<template>
  <TablePaginatedToggleButton
      label="Routes"
      :main-list="itemData"
      :is-global-percent="isGlobalPercent"
      @setActiveItem="setActiveRouteItem"
      is-mark-active-item
      :override-headers="computedOverrideHeaders"
      :tabs-list="tabsList"
      :is-has-tabs="isHasTabs"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TablePaginatedToggleButton from '../../../TablePaginatedToggleButton.vue';

export default {
  name: 'PagedListRoutes',
  components: {
    TablePaginatedToggleButton,
  },

  props: {
    itemData: {
      type: [Array],
      default() {
        return ([]);
      },
    },
  },

  methods: {
    ...mapActions({
      setActiveRouteNumber: 'metrics/setActiveRoute',
    }),

    setActiveRouteItem(item) {
      this.setActiveRouteNumber(this.itemData
      // eslint-disable-next-line max-len
        .findIndex((el) => (item.id != null && el.id === item.id) || (item.label != null && el.label === item.label)));
    },
  },

  data: () => ({
    overrideHeaders: [
      {
        text: '', value: 'label', width: '120px', tabs: ['every', 'default'],
      },
      {
        text: 'Route Activities', value: 'value', type: 'bar', tabs: ['every', 'default'],
      },
      {
        text: 'Route Built', value: 'route_build', type: 'number', tabs: ['details'],
      },
      {
        text: 'List ⟷ Map', value: 'list_to_map', type: 'number', tabs: ['details'],
      },
      {
        text: 'Date Selected', value: 'date_selected', type: 'number', tabs: ['details'],
      },
      {
        text: 'Flights Viewed', value: 'flights_viewed', type: 'number', tabs: ['details'],
      },
      {
        text: 'Flight Details', value: 'flight_details', type: 'number', tabs: ['details'],
      },
      {
        text: 'Booking Clicked', value: 'bookings', type: 'number', tabs: ['details'],
      },
    ],
    tabsList: [
      { text: 'Overview', name: 'default' },
      { text: 'Details', name: 'details' },
    ],
  }),

  computed: {
    ...mapGetters({
      isGlobalPercent: 'metrics/isGlobalPercent',
    }),
    isHasTabs() {
      return this.hasAnyAbility([
        this.$constants.ABILITY.DEVELOPER,
        this.$constants.ABILITY.HIDDEN_VIEW,
      ]);
    },
    computedOverrideHeaders() {
      if (this.hasAnyAbility([
        this.$constants.ABILITY.DEVELOPER,
        this.$constants.ABILITY.HIDDEN_VIEW,
      ])
      ) {
        return this.overrideHeaders;
      }

      return this.overrideHeaders.filter((el) => el.tabs.includes('default'));
    },
  },
};
</script>
