<template>
  <DoubleNumberFrame
      title="Average Time in Map (min)"
      :primary-value="$numberManagement.secondsToMinSecFormat(itemData.value1)"
      :secondary-value="$numberManagement.secondsToMinSecFormat(itemData.value2)"
      :changes-style="itemData.changes.style"
      :changes-value="itemData.changes.value"
      is-show-legend
      legend="per passenger"
  >
    <template v-slot:icon>
      <icon/>
    </template>
  </DoubleNumberFrame>
</template>

<script>

import icon from '../../../../../public/img/cil-cursor.svg';
import DoubleNumberFrame from './DoubleNumberFrame.vue';

export default {
  name: 'DoubleNumberFrameMapDuration',
  components: {
    icon,
    DoubleNumberFrame,
  },

  props: {
    itemData: {
      type: Object,
      default() {
        return ({});
      },
    },
  },
};
</script>
