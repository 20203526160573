<template>
  <GreyBarWithTitleAndValue
      is-with-borders
      title = "Destination Airports"
      :value = "$numberManagement.numberWithComma(itemData)"
  />
</template>

<script>

import GreyBarWithTitleAndValue from '../../../microcomponents/GreyBarWithTitleAndValue.vue';

export default {
  name: 'GrayBarDestinationsAirports',
  components: {
    GreyBarWithTitleAndValue,
  },

  props: {
    itemData: {
      type: [Number, String],
      default: null,
    },
  },
};
</script>
