<template>
  <div class="pie-holder">
    <ChartDoughnut
      class="pie"
      :chart-data="chartData"
      :options="doughnutChartOptions"
      :width="width"
      />
  </div>
</template>

<script>

import ChartDoughnut from '../../../ChartDoughnut.vue';

export default {
  name: 'DoughnutChart',
  components: {
    ChartDoughnut,
  },

  props: {
    itemData: {
      type: Array,
      default() {
        return ([]);
      },
    },

    label: {
      type: String,
      default: '',
    },

    width: {
      type: Number,
    },

    doughnutChartPalette: {
      type: Array,
      default: () => [
        'rgba(51, 169, 218, 1)',
        'rgba(72, 194, 228, 1)',
        'rgba(144, 220, 240, 1)',
      ],
    },
  },

  data() {
    return ({
    });
  },

  methods: {
    getSamplePaletteWithFixedLength(samplePalette, length) {
      if (samplePalette.length >= length) {
        return samplePalette.slice(0, length);
      }

      const longArray = [...samplePalette];
      for (let i = samplePalette.length; i < length; i += 1) {
        longArray.push(this.getSeededColor(i));
      }

      return longArray;
    },

    getSeededColor(seed) {
      const r = (seed * 11) % 255;
      const g = (seed * 48) % 255;
      const b = (seed * 400) % 255;

      return `rgba(${r}, ${g}, ${b}, 1)`;
    },
  },

  computed: {
    chartData() {
      return ({
        labels: this.itemData.map((el) => el.label),
        datasets: [{
          label: this.label,
          data: this.itemData.map((el) => el.value),
          // eslint-disable-next-line max-len
          backgroundColor: this.getSamplePaletteWithFixedLength(this.doughnutChartPalette, this.itemData.length),
          // eslint-disable-next-line max-len
          borderColor: this.getSamplePaletteWithFixedLength(this.doughnutChartPalette, this.itemData.length),
          borderWidth: 1,
        }],
      });
    },

    doughnutChartOptions: () => ({
      legend: {
        display: true,
        position: 'right',
      },
      responsive: false,
    }),
  },
};
</script>
<style scoped>
/*pies*/
.pies-row .card {
  height: 100%;
  margin-bottom: 0;
}

.pie-holder {
  padding: 25px 0;
}

.pie {
  margin: 0 auto;
}
</style>
