<template>
  <div class="sum">
        <div class="sum-txt">
          <h2>{{ title }}</h2>
          <h3 >
            {{ primaryValue }}
          </h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <p
                  v-bind="attrs"
                  v-on="on"
                  :class="changesStyle"
              >{{ changesValue }}</p>
            </template>
            {{ secondaryValue }}
          </v-tooltip>
          <p
              class="sum-legend"
              v-if="isShowLegend"
          >
            {{ legend }}
          </p>
        </div>
        <span class="icon">
          <slot name="icon" />
        </span>
      </div>
</template>

<script>

export default {
  name: 'DoubleNumberFrame',

  props: {
    title: {
      type: String,
      default: '',
    },

    primaryValue: {
      type: [Number, String],
      default: null,
    },

    secondaryValue: {
      type: [Number, String],
      default: null,
    },

    changesStyle: {
      type: String,
      default: '',
    },

    changesValue: {
      type: [Number, String],
      default: '',
    },

    isShowLegend: {
      type: Boolean,
      default: false,
    },

    legend: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
  .sum {
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-content: stretch;
    align-items: center;
    gap: 10px;
    background: #e4e4e4;
    border-radius: 8px;
    height: 100%;
  }

  .sum h2 {
    color: #969696;
    font-size: 20px;
    margin-bottom: 8px;
  }

  .icon {
    display: block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .icon img {
    display: block;
    width: 100%;
    height: 100%;
  }

  .sum h3 {
    font-size: 40px;
    margin-bottom: 8px;
  }

  .sum .key {
    font-size: 0.5em;
    color: #969696;
  }

  .sum p {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .sum .sum-legend {
    font-size: 14px;
    margin-bottom: 0;
  }

  .positive {
    color: green;
  }

  .negative {
    color: red;
  }
</style>
