<template>
  <div class="overview-sum">
    <h4>
      Active Passengers: <span>3 months</span>
    </h4>
    <div class="bar-chart-holder">
      <BarChart
          :item-data="itemData"
          label="Active passengers"
          :width="500"
          :height="150"
      />
    </div>
  </div>
</template>

<script>

import BarChart from './BarChart.vue';

export default {
  name: 'BarChartPassengersThreeMonths',
  components: {
    BarChart,
  },

  props: {
    itemData: {
      type: Array,
      default() {
        return ([]);
      },
    },
  },
};
</script>
<style scoped>
/*bar chart*/
.bar-chart-holder {
  padding: 0;
}

.bar-chart {
  margin: 0 auto;
}

.bar-chart-legend .legend-color {
  width: 2em;
  border-radius: 0;
}

.overview-sum {
  padding-right: 30px;
}

h4 {
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

</style>
