<template>
  <div
      :style="{ position: 'relative' }"
  >
    <SelectLayout
      v-if="isLayoutEmpty && isEditMode"
      :layout-item.sync="layoutEdit"
    />

    <OneRowLayout
        v-if="!isLayoutEmpty && layoutItem.layout.slug === 'one_row'"
        :layout-item.sync="layoutEdit.layout"
        :is-edit-mode="isEditMode"
    />

    <TwoRowsLayout
        v-if="!isLayoutEmpty && layoutItem.layout.slug === 'two_rows'"
        :layout-item.sync="layoutEdit.layout"
        :is-edit-mode="isEditMode"
    />

    <OverviewAirplaneLayout
        v-if="!isLayoutEmpty && layoutItem.layout.slug === 'overview_airplane'"
        :layout-item.sync="layoutEdit.layout"
        :is-edit-mode="isEditMode"
    />

    <OverviewAirportLayout
        v-if="!isLayoutEmpty && layoutItem.layout.slug === 'overview_airport'"
        :layout-item.sync="layoutEdit.layout"
        :is-edit-mode="isEditMode"
    />

    <v-tooltip
        bottom
        v-if="isEditMode && !isLayoutEmpty"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            icon @click="removeLayout"
            :style="{ position: 'absolute', right: 0, top: 0 }"
        >
          <v-icon
              color="red"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      remove layout {{ layoutItem.layout.slug }}
    </v-tooltip>
  </div>
</template>

<script>

import OverviewAirplaneLayout from './OverviewAirplaneLayout.vue';
import OneRowLayout from './OneRowLayout.vue';
import TwoRowsLayout from './TwoRowsLayout.vue';
import OverviewAirportLayout from './OverviewAirportLayout.vue';
import SelectLayout from './SelectLayout.vue';

export default {
  name: 'LayoutBuilder',
  components: {
    SelectLayout,
    OverviewAirportLayout,
    TwoRowsLayout,
    OneRowLayout,
    OverviewAirplaneLayout,
  },

  props: {
    layoutItem: {
      type: Object,
    },

    isEditMode: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    layoutItemForEdit: {},
  }),

  methods: {
    removeLayout() {
      this.$emit('update:layoutItem', ({ ...this.layoutItem, layout: {} }));
    },
  },

  computed: {
    isLayoutEmpty() {
      // eslint-disable-next-line max-len
      return this.layoutItem.layout === undefined || Object.keys(this.layoutItem.layout).length === 0;
    },

    layoutEdit: {
      get() {
        return this.layoutItem;
      },

      set(val) {
        this.$emit('update:layoutItem', ({ ...this.layoutItem, layout: val }));
      },
    },
  },
};
</script>
