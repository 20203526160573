<template>
  <DoubleNumberFrame
      title="Passengers"
      :primary-value="$numberManagement.contractInteger(itemData.value1)"
      :secondary-value="$numberManagement.contractInteger(itemData.value2)"
      :changes-style="itemData.changes.style"
      :changes-value="itemData.changes.value"
      :is-show-legend="isShowBounced"
      :legend="computedLegend"
  >
    <template v-slot:icon>
      <icon/>
    </template>
  </DoubleNumberFrame>
</template>

<script>

import { mapGetters } from 'vuex';
import icon from '../../../../../public/img/cil-group.svg';
import DoubleNumberFrame from './DoubleNumberFrame.vue';

export default {
  name: 'DoubleNumberFramePassengers',
  components: {
    icon,
    DoubleNumberFrame,
  },

  props: {
    itemData: {
      type: Object,
      default() {
        return ({});
      },
    },
  },

  computed: {
    ...mapGetters({
      isCompositeCustomerHasAirport: 'metrics/isCompositeCustomerHasAirport',
    }),

    isShowBounced() {
      // eslint-disable-next-line max-len
      return this.hasAllAbilities([this.$constants.ABILITY.DEVELOPER]) && this.isCompositeCustomerHasAirport;
    },

    computedLegend() {
      return `Bounced: ${this.$numberManagement.contractInteger(this.itemData.bounced)} `;
    },
  },
};
</script>
