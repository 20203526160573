<template>
  <div
      :style="{ position: 'relative' }"
  >
    <div
        v-if="isMetricEmpty"
    >
      <div v-if="isEditMode">
        <SelectMetric
            :metric-item.sync="metricEdit"
        />
      </div>
    </div>

    <DoubleNumberFramePassengers
        v-if="!isMetricEmpty && metricItem.slug === 'passenger_number'"
        :item-data="elementData"
    />

    <DoubleNumberFrameMapDuration
        v-if="!isMetricEmpty && metricItem.slug === 'duration_on_map'"
        :item-data="elementData"
    />

    <DoubleNumberFramePlacesViewed
        v-if="!isMetricEmpty && ['places_viewed', 'places_viewed_native'].includes(metricItem.slug)"
        :item-data="elementData"
    />

    <DoubleNumberFrameRoutesViewed
        v-if="!isMetricEmpty && metricItem.slug === 'routes_viewed'"
        :item-data="elementData"
    />

    <BarChartPassengersThreeMonths
        v-if="!isMetricEmpty && metricItem.slug === 'passengers_three_months'"
        :item-data="elementData"
    />

    <SimpleListPopularDestinations
        v-if="!isMetricEmpty && ['popular_destinations_short', 'popular_destinations_short_native']
          .includes(metricItem.slug)"
        :item-data="elementData"
    />

    <SimpleListPopularPlaces
        v-if="!isMetricEmpty && ['popular_places_short', 'popular_places_short_native']
          .includes(metricItem.slug)"
        :item-data="elementData"
    />

    <DoughnutChartInterestsShort
        v-if="!isMetricEmpty && ['interests_chart_short', 'interests_chart_short_native']
          .includes(metricItem.slug)"
        :item-data="elementData"
    />

    <DoughnutChartLanguagesShort
        v-if="!isMetricEmpty && metricItem.slug === 'language_chart_short'"
        :item-data="elementData"
    />

    <SingleNumberFrameMobiles
        v-if="!isMetricEmpty && metricItem.slug === 'mobile_number_type_one'"
        :item-data="elementData"
    />

    <SingleNumberFrameTablets
        v-if="!isMetricEmpty && metricItem.slug === 'tablet_number_type_one'"
        :item-data="elementData"
    />

    <SingleNumberFrameLaptops
        v-if="!isMetricEmpty && metricItem.slug === 'laptop_number_type_one'"
        :item-data="elementData"
    />

    <BarChartPassengers
        v-if="!isMetricEmpty && metricItem.slug === 'active_passengers_chart'"
        :item-data="elementData"
    />

    <HorizontalBarChartFeatures
        v-if="!isMetricEmpty && metricItem.slug === 'feature_usage_chart'"
        :item-data="elementData"
    />

    <HorizontalBarChartFeaturesAutoplay
        v-if="!isMetricEmpty && metricItem.slug === 'feature_autoplay_usage_chart'"
        :item-data="elementData"
    />

    <HorizontalBarChartFeaturesNotAutoplay
        v-if="!isMetricEmpty && metricItem.slug === 'feature_not_autoplay_usage_chart'"
        :item-data="elementData"
    />

    <BarChartVideoNumber
        v-if="!isMetricEmpty && metricItem.slug === 'video_number_chart'"
        :item-data="elementData"
    />

    <BarChartVideoDuration
        v-if="!isMetricEmpty && metricItem.slug === 'video_duration_chart'"
        :item-data="elementData"
    />

    <PagedListPopularDestinations
        v-if="!isMetricEmpty
          && ['popular_destinations_paged_list', 'popular_destinations_paged_list_native']
          .includes(metricItem.slug)"
        :item-data="elementData"
    />

    <PagedListPopularPlaces
        v-if="!isMetricEmpty && metricItem.slug === 'popular_places_paged_list'"
        :item-data="elementData"
    />

    <PagedListPopularPlacesNative
        v-if="!isMetricEmpty && metricItem.slug === 'popular_places_paged_list_native'"
        :item-data="elementData"
    />

    <PagedListPopularPlacesNoHighlights
        v-if="!isMetricEmpty && metricItem.slug === 'popular_places_paged_list_no_highlights'"
        :item-data="elementData"
    />

    <PagedListRoutes
        v-if="!isMetricEmpty && metricItem.slug === 'routes_paged_list'"
        :item-data="elementData"
    />

    <PagedListFilters
        v-if="!isMetricEmpty && metricItem.slug === 'filters_paged_list'"
        :item-data="elementData"
    />

    <PagedListFilterValues
        v-if="!isMetricEmpty && metricItem.slug === 'filter_values_paged_list'"
        :item-data="elementData"
    />

    <PagedListInterests
        v-if="!isMetricEmpty && metricItem.slug === 'interests_paged_list'"
        :item-data="elementData"
    />

    <PagedListLanguages
        v-if="!isMetricEmpty && metricItem.slug === 'language_paged_list'"
        :item-data="elementData"
    />

    <SingleNumberFrameMobiles
        v-if="!isMetricEmpty && metricItem.slug === 'mobile_number_type_two'"
        :item-data="elementData"
        icon-color="white"
    />

    <SingleNumberFrameTablets
        v-if="!isMetricEmpty && metricItem.slug === 'tablet_number_type_two'"
        :item-data="elementData"
        icon-color="white"
    />

    <SingleNumberFrameLaptops
        v-if="!isMetricEmpty && metricItem.slug === 'laptop_number_type_two'"
        :item-data="elementData"
        icon-color="white"
    />

    <AccordionDevices
        v-if="!isMetricEmpty && metricItem.slug === 'device_accordion'"
        :item-data="elementData"
    />

    <AccordionOperatingSystem
        v-if="!isMetricEmpty && metricItem.slug === 'operating_system_accordion'"
        :item-data="elementData"
    />

    <AccordionBrowsers
        v-if="!isMetricEmpty && metricItem.slug === 'browser_accordion'"
        :item-data="elementData"
    />

    <PagedListTailNumberUsers
        v-if="!isMetricEmpty && metricItem.slug === 'tail_numbers_paged_list'"
        :item-data="elementData"
    />

    <PagedListFlightNumberUsers
        v-if="!isMetricEmpty && metricItem.slug === 'flight_numbers_paged_list'"
        :item-data="elementData"
    />

    <SingleNumberTailNumberNumbers
        v-if="!isMetricEmpty && metricItem.slug === 'tail_numbers_number'"
        :item-data="elementData"
    />

    <SingleNumberFlightNumberNumbers
        v-if="!isMetricEmpty && metricItem.slug === 'flight_numbers_number'"
        :item-data="elementData"
    />

    <BarChartTailNumbers
        v-if="!isMetricEmpty && metricItem.slug === 'tail_numbers_chart'"
        :item-data="elementData"
    />

    <BarChartFlightNumbers
        v-if="!isMetricEmpty && metricItem.slug === 'flight_numbers_chart'"
        :item-data="elementData"
    />

    <GrayBarDestinationsAirports
        v-if="!isMetricEmpty && metricItem.slug === 'rm_airport_number'"
        :item-data="elementData"
    />

    <GrayBarDeparturesPerMonth
        v-if="!isMetricEmpty && metricItem.slug === 'rm_flights_per_month'"
        :item-data="elementData"
    />

    <GrayBarGuidesNumber
        v-if="!isMetricEmpty && metricItem.slug === 'ap_tb_guides_number'"
        :item-data="elementData"
    />

    <GrayBarPoisNumber
        v-if="!isMetricEmpty && metricItem.slug === 'ap_tb_pois_number'"
        :item-data="elementData"
    />

    <GrayBarReelsNumber
        v-if="!isMetricEmpty && metricItem.slug === 'ap_tb_reels_number'"
        :item-data="elementData"
    />

    <SimpleListAirportPopularDestinations
        v-if="!isMetricEmpty && metricItem.slug === 'ap_popular_destinations_short'"
        :item-data="elementData"
    />

    <SimpleListAirportPopularRoutes
        v-if="!isMetricEmpty && metricItem.slug === 'ap_popular_routes_short'"
        :item-data="elementData"
    />

    <SimpleListAirportPopularInterests
        v-if="!isMetricEmpty && metricItem.slug === 'ap_popular_interests_short'"
        :item-data="elementData"
    />

    <PagedListMicrosites
        v-if="!isMetricEmpty && metricItem.slug === 'microsites_paged_list'"
        :item-data="elementData"
    />

    <PagedListMicrositesNoHighlights
        v-if="!isMetricEmpty && metricItem.slug === 'microsites_paged_list_no_highlights'"
        :item-data="elementData"
    />

    <PagedListPlaylists
        v-if="!isMetricEmpty && metricItem.slug === 'playlists_paged_list'"
        :item-data="elementData"
    />

    <PagedListPlaylistsNoHighlights
        v-if="!isMetricEmpty && metricItem.slug === 'playlists_paged_list_no_highlights'"
        :item-data="elementData"
    />

    <PagedListAdvertisement
        v-if="!isMetricEmpty && metricItem.slug === 'advertisement_paged_list'"
        :item-data="elementData"
    />

    <TabsDestinationRelations
        v-if="!isMetricEmpty && metricItem.slug === 'destination_relations_tabs'"
        :item-data="elementData"
    />
    <TabsDestinationRelationsNoHighlights
        v-if="!isMetricEmpty && metricItem.slug === 'destination_relations_tabs_no_highlights'"
        :item-data="elementData"
    />

    <PagedListFlightsAndUsers
        v-if="!isMetricEmpty && metricItem.slug === 'flight_numbers_and_users_paged_list'"
        :item-data="elementData"
    />

    <v-tooltip
        bottom
        v-if="isEditMode && !isMetricEmpty"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-bind="attrs"
            v-on="on"
            icon @click="removeMetric"
            :style="{ position: 'absolute', left: 0, top: 0 }"
        >
          <v-icon
              color="orange"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      remove metric {{ metricItem.slug }}
    </v-tooltip>

  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import DoubleNumberFramePassengers from './simple/DoubleNumberFramePassengers.vue';
import DoubleNumberFrameMapDuration from './simple/DoubleNumberFrameMapDuration.vue';
import DoubleNumberFramePlacesViewed from './simple/DoubleNumberFramePlacesViewed.vue';
import DoubleNumberFrameRoutesViewed from './simple/DoubleNumberFrameRoutesViewed.vue';
import BarChartPassengersThreeMonths from './charts/BarChartPassengersThreeMonths.vue';
import SimpleListPopularDestinations from './simpleLists/SimpleListPopularDestinations.vue';
import SimpleListPopularPlaces from './simpleLists/SimpleListPopularPlaces.vue';
import DoughnutChartInterestsShort from './charts/DoughnutChartInterestsShort.vue';
import DoughnutChartLanguagesShort from './charts/DoughnutChartLanguagesShort.vue';
import SingleNumberFrameMobiles from './simple/SingleNumberFrameMobiles.vue';
import SingleNumberFrameTablets from './simple/SingleNumberFrameTablets.vue';
import SingleNumberFrameLaptops from './simple/SingleNumberFrameLaptops.vue';
import BarChartVideoDuration from './charts/BarChartVideoDuration.vue';
import BarChartVideoNumber from './charts/BarChartVideoNumber.vue';
import HorizontalBarChartFeatures from './charts/HorizontalBarChartFeatures.vue';
import BarChartPassengers from './charts/BarChartPassengers.vue';
import PagedListPopularDestinations from './pagedLists/PagedListPopularDestinations.vue';
import PagedListPopularPlaces from './pagedLists/PagedListPopularPlaces.vue';
import PagedListPopularPlacesNoHighlights from './pagedLists/PagedListPopularPlacesNoHighlights.vue';
import PagedListRoutes from './pagedLists/PagedListRoutes.vue';
import PagedListFilters from './pagedLists/PagedListFilters.vue';
import PagedListFilterValues from './pagedLists/PagedListFilterValues.vue';
import PagedListInterests from './pagedLists/PagedListInterests.vue';
import PagedListLanguages from './pagedLists/PagedListLanguages.vue';
import AccordionBrowsers from './accordions/AccordionBrowsers.vue';
import AccordionOperatingSystem from './accordions/AccordionOperatingSystem.vue';
import AccordionDevices from './accordions/AccordionDevices.vue';
import PagedListTailNumberUsers from './pagedLists/PagedListTailNumberUsers.vue';
import PagedListFlightNumberUsers from './pagedLists/PagedListFlightNumberUsers.vue';
import SingleNumberTailNumberNumbers from './simple/SingleNumberTailNumberNumbers.vue';
import SingleNumberFlightNumberNumbers from './simple/SingleNumberFlightNumberNumbers.vue';
import BarChartTailNumbers from './charts/BarChartTailNumbers.vue';
import BarChartFlightNumbers from './charts/BarChartFlightNumbers.vue';
import GrayBarDeparturesPerMonth from './simple/GrayBarDeparturesPerMonth.vue';
import GrayBarDestinationsAirports from './simple/GrayBarDestinationsAirports.vue';
import GrayBarGuidesNumber from './simple/GrayBarGuidesNumber.vue';
import GrayBarPoisNumber from './simple/GrayBarPoisNumber.vue';
import GrayBarReelsNumber from './simple/GrayBarReelsNumber.vue';
import SimpleListAirportPopularDestinations from './simpleLists/SimpleListAirportPopularDestinations.vue';
import SimpleListAirportPopularRoutes from './simpleLists/SimpleListAirportPopularRoutes.vue';
import SimpleListAirportPopularInterests from './simpleLists/SimpleListAirportPopularInterests.vue';
import SelectMetric from './SelectMetric.vue';
import PagedListMicrosites from './pagedLists/PagedListMicrosites.vue';
import PagedListMicrositesNoHighlights from './pagedLists/PagedListMicrositesNoHighlights.vue';
import PagedListPlaylists from './pagedLists/PagedListPlaylists.vue';
import PagedListPlaylistsNoHighlights from './pagedLists/PagedListPlaylistsNoHighlights.vue';
import PagedListAdvertisement from './pagedLists/PagedListAdvertisement.vue';
import TabsDestinationRelations from './tabs/TabsDestinationRelations.vue';
import TabsDestinationRelationsNoHighlights from './tabs/TabsDestinationRelationsNoHighlights.vue';
import PagedListPopularPlacesNative from './pagedLists/PagedListPopularPlacesNative.vue';
import PagedListFlightsAndUsers from './pagedLists/PagedListFlightsAndUsers.vue';
import HorizontalBarChartFeaturesAutoplay from './charts/HorizontalBarChartFeaturesAutoplay.vue';
import HorizontalBarChartFeaturesNotAutoplay from './charts/HorizontalBarChartFeaturesNotAutoplay.vue';

export default {
  name: 'WidgetBuilder',
  components: {
    HorizontalBarChartFeaturesNotAutoplay,
    HorizontalBarChartFeaturesAutoplay,
    PagedListFlightsAndUsers,
    PagedListPopularPlacesNative,
    TabsDestinationRelationsNoHighlights,
    TabsDestinationRelations,
    PagedListAdvertisement,
    PagedListPlaylistsNoHighlights,
    PagedListPlaylists,
    PagedListMicrositesNoHighlights,
    PagedListMicrosites,
    PagedListPopularPlacesNoHighlights,
    SelectMetric,
    SimpleListAirportPopularInterests,
    SimpleListAirportPopularRoutes,
    SimpleListAirportPopularDestinations,
    GrayBarReelsNumber,
    GrayBarPoisNumber,
    GrayBarGuidesNumber,
    GrayBarDestinationsAirports,
    GrayBarDeparturesPerMonth,
    BarChartFlightNumbers,
    BarChartTailNumbers,
    SingleNumberFlightNumberNumbers,
    SingleNumberTailNumberNumbers,
    PagedListFlightNumberUsers,
    PagedListTailNumberUsers,
    AccordionDevices,
    AccordionOperatingSystem,
    AccordionBrowsers,
    PagedListLanguages,
    PagedListInterests,
    PagedListFilterValues,
    PagedListFilters,
    PagedListRoutes,
    PagedListPopularPlaces,
    PagedListPopularDestinations,
    HorizontalBarChartFeatures,
    BarChartPassengers,
    BarChartVideoNumber,
    BarChartVideoDuration,
    SingleNumberFrameMobiles,
    SingleNumberFrameTablets,
    SingleNumberFrameLaptops,
    DoughnutChartLanguagesShort,
    DoughnutChartInterestsShort,
    SimpleListPopularPlaces,
    SimpleListPopularDestinations,
    BarChartPassengersThreeMonths,
    DoubleNumberFrameRoutesViewed,
    DoubleNumberFramePlacesViewed,
    DoubleNumberFrameMapDuration,
    DoubleNumberFramePassengers,
  },

  props: {
    metricItem: {
      type: Object,
    },

    isEditMode: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    removeMetric() {
      this.$emit('update:metricItem', ({ slug: 'empty', metrics: [] }));
    },
  },

  computed: {
    ...mapGetters({
      metricDataBySlug: 'metrics/metricDataBySlug',
    }),

    isMetricEmpty() {
      // eslint-disable-next-line max-len
      return this.metricItem === undefined || this.metricItem.slug === 'empty';
    },

    elementData() {
      return this.metricDataBySlug(this.metricItem.slug) === undefined
        ? []
        : this.metricDataBySlug(this.metricItem.slug);
    },

    metricEdit: {
      get() {
        return this.metricItem;
      },

      set(val) {
        this.$emit('update:metricItem', ({ ...this.metricItem, ...val }));
      },
    },
  },
};
</script>
