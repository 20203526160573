<template>
  <div>
    <div class="nrow ncol-1">
      <div class="ncol">
        <div class="card">
          <div class="overview">
            <div class="overview-main">
              <span class="icon">
                <cilCursorBlueSvg/>
              </span>
              <div class="overview-txt">
                <h3>Engagement</h3>
                <p>
                  How much are your passengers using the app?
                </p>
              </div>
            </div>
            <div class="overview-content">
              <WidgetBuilder
                  v-for="num in layoutItem.positions.row_1.options.max_capacity"
                  :key="num"
                  :metric-item.sync="layoutItem.positions.row_1.metric_items[num - 1]"
                  :is-edit-mode="isEditMode"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nrow ncol-1">
      <div class="ncol">
        <div class="card">
          <div class="overview">
            <div class="overview-main">
              <span class="icon">
                <cilMapBlueSvg/>
              </span>
              <div class="overview-txt">
                <h3>Destinations</h3>
                <p>
                  Where do your passengers like to explore?
                </p>
              </div>
            </div>
            <div class="overview-content">
              <WidgetBuilder
                  v-for="num in layoutItem.positions.row_2.options.max_capacity"
                  :key="num"
                  :metric-item.sync="layoutItem.positions.row_2.metric_items[num - 1]"
                  :is-edit-mode="isEditMode"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nrow ncol-1">
      <div class="ncol">
        <div class="card">
          <div class="overview">
            <div class="overview-main">
              <span class="icon">
                <cilGroupBlueSvg/>
              </span>
              <div class="overview-txt">
                <h3>Passengers</h3>
                <p>
                  Learn more about your passengers
                </p>
              </div>
            </div>
            <div class="overview-content">
              <div class="overview-sum pie-sum">
                <WidgetBuilder
                    v-for="num in layoutItem.positions.row_3.options.max_capacity"
                    :key="num"
                    :metric-item.sync="layoutItem.positions.row_3.metric_items[num - 1]"
                    :is-edit-mode="isEditMode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nrow ncol-1">
      <div class="ncol">
        <div class="card">
          <div class="overview">
            <div class="overview-main">
              <span class="icon">
                <cilMobileBlueSvg/>
              </span>
              <div class="overview-txt">
                <h3>Devices</h3>
                <p>
                  Learn more about your passengers
                </p>
              </div>
            </div>
            <div class="overview-content">
              <WidgetBuilder
                  v-for="num in layoutItem.positions.row_4.options.max_capacity"
                  :key="num"
                  :metric-item.sync="layoutItem.positions.row_4.metric_items[num - 1]"
                  :is-edit-mode="isEditMode"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import WidgetBuilder from '../widgets/WidgetBuilder.vue';
import cilMapBlueSvg from '../../../../public/img/cil-map-blue.svg';
import cilMobileBlueSvg from '../../../../public/img/cil-mobile-blue.svg';
import cilGroupBlueSvg from '../../../../public/img/cil-group-blue.svg';
import cilCursorBlueSvg from '../../../../public/img/cil-cursor-blue.svg';

export default {
  name: 'OverviewAirplaneLayout',
  components: {
    cilCursorBlueSvg,
    cilGroupBlueSvg,
    cilMobileBlueSvg,
    cilMapBlueSvg,
    WidgetBuilder,
  },
  props: {
    layoutItem: {
      type: Object,
      default: () => ({}),
    },

    isEditMode: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.nrow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  padding-bottom: 25px;
}

.ncol-1 .ncol {
  width: 100%;
}
.card {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
}

.item-clicker:hover {
  cursor: pointer;
}

.card h3 {
  font-size: 20px;
  margin-bottom: 8px;
}

.card p {
  font-size: 18px;
  color: #bfbfbf;
  margin-bottom: 8px;
}

/*overview*/

.overview {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}

.overview-main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  box-sizing: border-box;
  width: 25%;
  padding-left: 10px;
  padding-right: 20px;
  margin-right: 30px;
  border-right: 1px solid #ccc;
  gap: 30px;
}

.overview-txt {
  flex: 1;
}

.overview-txt h3 {
  color: #5ab4e6;
  font-size: 24px;
  font-weight: 500;
}

.overview-txt p {
  color: #3d3d3d;
  opacity: 0.5;
}

.overview-content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-content: stretch;
  align-items: stretch;
}

.overview .bar-chart-holder {
  padding: 0;
}

.overview-sum {
  padding-right: 30px;
}

.overview-sum h4 {
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.overview-sum ol {
  list-style-position: inside;
}

.overview-sum li {
  padding: 0.5em 0;
}

.overview-content .sum {
  background: none;
}

.icon {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
}

.icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.pie-sum {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  gap: 30px;
}
</style>
