<template>
  <SingleNumberFrame
      title="Mobile"
      :value="itemData + ' %'"
  >
    <template v-slot:icon>
      <icon-blue
          v-if="iconColor === 'blue'"
      />
      <icon-white
          v-if="iconColor === 'white'"
      />
    </template>
  </SingleNumberFrame>
</template>

<script>

import iconBlue from '../../../../../public/img/cil-mobile-blue.svg';
import iconWhite from '../../../../../public/img/cil-mobile.svg';
import SingleNumberFrame from './SingleNumberFrame.vue';

export default {
  name: 'SingleNumberFrameTablets',
  components: {
    iconBlue,
    iconWhite,
    SingleNumberFrame,
  },

  props: {
    itemData: {
      type: [Number, String],
      default: null,
    },

    iconColor: {
      type: String,
      default: 'blue',
    },
  },
};
</script>
