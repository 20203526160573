<template>
  <div class="card">
    <h3>
      Videos
    </h3>
    <p>
      What is the total number of videos watched by your passengers?
    </p>
    <div class="bar-chart-holder">
      <BarChart
          class="bar-chart"
          label="video amount"
          :item-data="itemData"
          :width="600"
          :height="300"
      />
    </div>
  </div>
</template>

<script>

import BarChart from './BarChart.vue';

export default {
  name: 'BarChartVideoNumber',
  components: {
    BarChart,
  },

  props: {
    itemData: {
      type: Array,
      default() {
        return ([]);
      },
    },
  },
};
</script>
<style scoped>
/*bar chart*/
.bar-chart-holder {
  padding: 0;
}

.bar-chart {
  margin: 0 auto;
}

h4 {
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.card h3 {
  font-size: 20px;
  margin-bottom: 8px;
}

.card p {
  font-size: 18px;
  color: #bfbfbf;
  margin-bottom: 8px;
}
</style>
