<template>
  <div class="card">
    <h3>
      {{ title }}
    </h3>
    <p>
      {{ subtitle }}
    </p>
    <v-expansion-panels
        class="range-list"
        :disabled="hasAllAbilities([$constants.ABILITY.CUSTOMER])"
    >
      <v-expansion-panel
          v-for="(item, i) in $arrayManagement.normalizedDataArray(itemData)"
          :key="i"
      >
        <v-expansion-panel-header>
          <div class="range-line">
            <span class="key">{{ item.label }}</span>
            <span class="value">{{
                isGlobalPercent
                    ? `${item.ratio}%`
                    : $numberManagement.contractInteger(item.value)
              }}
            </span>
            <span class="bar-holder">
              <span class="bar-fill" :style="`width: ${item.ratio}%;`"/>
            </span>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="range-sub-line">
            <ul class="range-list">
              <li
                v-for="(subItem, j) in $arrayManagement.normalizedDataArray(itemData[i].versions)"
                :key="j"
              >
                <div class="range-line">
                  <span class="key">{{ subItem.label }}</span>
                  <span class="value">{{
                      isGlobalPercent
                          ? `${subItem.ratio}%`
                          : $numberManagement.contractInteger(subItem.value)
                    }}
                  </span>
                  <span class="bar-holder">
                    <span class="bar-fill" :style="`width: ${subItem.ratio}%;`"/>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'Accordion',

  props: {
    title: {
      type: String,
      default: '',
    },

    subtitle: {
      type: String,
      default: '',
    },

    itemData: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters({
      isGlobalPercent: 'controls/isGlobalPercent',
    }),
  },
};
</script>

<style scoped>
.card {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 8px;
}

.card h3 {
  font-size: 20px;
  margin-bottom: 8px;
}

.card p {
  font-size: 18px;
  color: #bfbfbf;
  margin-bottom: 8px;
}

/*range-list*/
.range-list {
  padding-top: 8px;
}

.range-list .v-expansion-panel-header {
  width: 100% !important;
}

.range-sub-line .range-list {
  padding-top: 0;
}

.range-line {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: center;
  padding: 8px 0;
}

.range-sub-line {
  padding: 1em;
  background: #f0f0f0;
}

.range-list .key {
  flex: 1 1 auto;
}

.range-list.with-dd .key {
  cursor: pointer;
  text-decoration: 2px underline dotted;
}

.range-list.with-dd .range-sub-line .key {
  cursor: default;
  text-decoration: none;
}

.range-list .value {
  padding-right: 0.5em;
}

.range-list .bar-holder {
  display: block;
  height: 1.2em;
  width: 100px;
}

.range-list .bar-fill {
  display: block;
  height: 100%;
  background: #0fb0e1;
  width: 0;
}

</style>
