<template>
  <div class="card">
    <h3>
      Feature Usage Autoplay
    </h3>
    <p>
      Which map features are the most popular among passengers?
    </p>
    <div class="bar-chart-holder">
      <HorizontalBarChart
          class="bar-chart"
          label="Active passengers"
          :item-data="itemData"
          :width="600"
          :height="300"
      />
    </div>
  </div>
</template>

<script>

import HorizontalBarChart from './HorizontalBarChart.vue';

export default {
  name: 'HorizontalBarChartFeaturesAutoplay',
  components: {
    HorizontalBarChart,
  },

  props: {
    itemData: {
      type: Array,
      default() {
        return ([]);
      },
    },
  },
};
</script>
<style scoped>
/*bar chart*/
.bar-chart-holder {
  padding: 0;
}

.bar-chart {
  margin: 0 auto;
}

.bar-chart-legend .legend-color {
  width: 2em;
  border-radius: 0;
}

.overview-sum {
  padding-right: 30px;
}

h4 {
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

.card h3 {
  font-size: 20px;
  margin-bottom: 8px;
}

.card p {
  font-size: 18px;
  color: #bfbfbf;
  margin-bottom: 8px;
}
</style>
