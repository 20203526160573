<template>
  <div
      v-if="url.length"
      :class="{ popupIframeMap:  isPopUp }"
  >
    <div class="overlay overlay-iframe">
      <iframe class="pop-iframe"
              id="tb-iframe"
              :src="url"
      ></iframe>
      <div class="show-map">
        <div
            class="icon popup-open"
            @click="toggleClass"
        >
          <svg viewBox="0 0 489.001 489.001">
            <path d="M2.2,168.151l-2.1-151.3c-0.1-7.7,6.2-14,13.9-13.9l151.3,2.2c12.1,0.2,
            18.1,14.8,9.5,23.4l-42.1,
            42.1l70,70l-65,65l-70-70l-42.1,42.1C17.1,186.251,2.4,180.251,2.2,168.151z"/>
            <path d="M421.3,136.551l42.1,42.1c8.6,8.6,23.2,2.6,
            23.4-9.5l2.2-151.3c0.1-7.7-6.2-14-13.9-13.9l-151.3,2.2
    c-12.1,0.2-18.1,14.8-9.5,23.4l42,41.9l-70,70l65,65L421.3,136.551z"/>
            <path d="M314.2,460.451c-8.6,8.6-2.6,23.2,9.5,23.4l151.3,2.2c7.7,0.1,14-6.2,
            13.9-13.9l-2.2-151.3c-0.2-12.1-14.8-18.1-23.4-9.5l-42.1,42.1l-70-70l-65,
            65l70,70L314.2,460.451z"/>
            <path d="M14,485.051l151.3-2.2c12.1-0.2,18.1-14.8,
            9.5-23.4l-42.1-42l70-70l-65-65l-70,70l-42.1-42.1c-8.6-8.6-23.2-2.6-23.4,9.5
    L0,471.151C0,478.851,6.3,485.151,14,485.051z"/>

          </svg>
        </div>
        <div
            class="icon popup-close"
            @click="toggleClass"
        >
          <svg viewBox="0 0 121.31 122.876">
            <path d="M90.914,5.296c6.927-7.034,18.188-7.065,25.154-0.068 c6.961,6.995,
            6.991,18.369,0.068,25.397L85.743,61.452l30.425,30.855c6.866,6.978,6.773,
            18.28-0.208,25.247 c-6.983,6.964-18.21,6.946-25.074-0.031L60.669,
            86.881L30.395,117.58c-6.927,7.034-18.188,
            7.065-25.154,0.068 c-6.961-6.995-6.992-18.369-0.068-25.397l30.393-30.827L5.142,
            30.568c-6.867-6.978-6.773-18.28,0.208-25.247 c6.983-6.963,18.21-6.946,25.074,
            0.031l30.217,
            30.643L90.914,5.296L90.914,5.296z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AppIframe',
  props: {
    url: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isPopUp: false,
    };
  },

  methods: {
    toggleClass() {
      this.isPopUp = !this.isPopUp;
    },
  },

};
</script>
<style scoped>
.overlay {
  width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: center;
}
.popupIframeMap .map-preview img {
  display: none;
}

.pop-iframe {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top:0;
  left: 0;
  z-index: 1;
}
.popupIframeMap .overlay-iframe
{
  background: rgba(2, 55, 100, 0.7);
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 40px;
  padding-bottom: 40px;
  top: 0;
  left: 0;
  overflow: hidden;
}
.popupIframeMap .overlay-iframe .pop-iframe
{
  position: relative;
  box-sizing: border-box;
  width: calc(100% - 80px);
  height: 100%;
  max-width: 1200px;
  border:10px solid #fff;
  border-radius: 5px;
  box-shadow: 0 0 50px 20px rgba(0,0,0,0.4);
}

.show-map {
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 10px;
}

.show-map .icon {
  cursor: pointer;
}
.show-map .icon.popup-open{
  width: 20px;
  height: 20px;
}

.show-map .icon.popup-close{
  width: 60px;
  height: 60px;
}

.show-map svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #fff;
}
.popup-open {
  display: block;
}
.popup-close {
  display: none;
}
.popupIframeMap .popup-open {
  display: none;
}
.popupIframeMap .popup-close {
  display: block;
}
</style>
