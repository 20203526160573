<template>
  <div class="empty-layout">
    Select Layout
    <v-row
        justify="center"
        align="center"
    >
      <v-col
        :cols="1"
      >
        <v-btn
            color="primary"
            @click="setLayout"
            :disabled="!selectedLayoutId"
        >
          use
        </v-btn>
      </v-col>
      <v-col :cols="3">
        <v-select
            label="layouts"
            v-model="selectedLayoutId"
            :items="layoutList.list"
            item-text="slug"
            item-value="id"
        />
      </v-col>
      <v-col :cols="8">
        {{ selectedLayout.description }}
      </v-col>
    </v-row>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SelectLayout',

  props: {
    layoutItem: {
      type: Object,
    },
  },

  data: () => ({
    layoutList: [],

    selectedLayoutId: null,
  }),

  async created() {
    this.layoutList = this.listItem(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_LAYOUTS.type);
    await this.updateList(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_LAYOUTS);
  },

  methods: {
    ...mapActions({
      updateList: 'datalists/updateList',
    }),

    setLayout() {
      this.$emit(
        'update:layoutItem',
        {
          slug: this.selectedLayout.slug,
          positions: this.selectedLayout.options.positions,
        },
      );
    },
  },

  computed: {
    ...mapGetters({
      listItem: 'datalists/listItem',
    }),

    selectedLayout() {
      const layout = this.layoutList.list.find((el) => el.id === this.selectedLayoutId);

      return !layout ? { description: '' } : layout;
    },
  },
};
</script>

<style scoped>

</style>
