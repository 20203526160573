<template>
  <v-container>
    <v-tabs
        v-model="activeTab"
    >
      <v-tab
          v-for="(tab, key) in tabs"
          :key="key"
      >
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items
        v-model="activeTab"
    >
      <v-tab-item
          v-for="(tab, key) in tabs"
          :key="key"
      >
        <slot :name="tab.slug"></slot>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>

export default {
  name: 'TabsComponent',
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },

    isActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      activeTab: 0,
    };
  },

  mounted() {
    this.reset();
  },

  methods: {
    reset() {
      this.activeTab = 0;
    },
  },
};
</script>

<style scoped>
:deep(.is-active) {
  background-color: #e4ede9;
}

</style>
