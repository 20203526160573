<template>
  <TablePaginatedToggleButton
      label="Advertisement"
      :main-list="itemData"
      :is-global-percent="isGlobalPercent"
      :visible-pages-numbers="5"
      is-has-tabs
      is-show-total-label
  />
</template>

<script>
import { mapGetters } from 'vuex';
import TablePaginatedToggleButton from '../../../TablePaginatedToggleButton.vue';

export default {
  name: 'PagedListAdvertisement',
  components: {
    TablePaginatedToggleButton,
  },

  props: {
    itemData: {
      type: [Array],
      default() {
        return ([]);
      },
    },
  },

  data: () => ({

  }),

  computed: {
    ...mapGetters({
      isGlobalPercent: 'metrics/isGlobalPercent',
    }),
  },
};
</script>
