<template>
  <SimpleList
      emphasized-text="Popular Places"
      :main-list="itemData"
      :is-show-in-percent="isGlobalPercent"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import SimpleList from '../../../SimpleList.vue';

export default {
  name: 'SimpleListPopularPlaces',
  components: {
    SimpleList,
  },

  props: {
    itemData: {
      type: [Array],
      default() {
        return ([]);
      },
    },
  },

  computed: {
    ...mapGetters({
      isGlobalPercent: 'metrics/isGlobalPercent',
    }),
  },
};
</script>
