<template>
    <DoughnutChart
        :item-data="itemData"
        label="Interests"
        :width="320"
    />
</template>

<script>

import DoughnutChart from './DoughnutChart.vue';

export default {
  name: 'DoughnutChartInterestsShort',
  components: {
    DoughnutChart,
  },

  props: {
    itemData: {
      type: Array,
      default() {
        return ([]);
      },
    },
  },
};
</script>
<style scoped>
/*bar chart*/
.bar-chart-holder {
  padding: 0;
}

.bar-chart {
  margin: 0 auto;
}

.bar-chart-legend .legend-color {
  width: 2em;
  border-radius: 0;
}

.overview-sum {
  padding-right: 30px;
}

h4 {
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 500;
}

</style>
