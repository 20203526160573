<template>
  <div
     class="nrow"
    :class="layoutClass"
  >
    <div
        v-for="index in layoutItem.positions[`row_${rowNumber}`].options.max_capacity"
        class="ncol"
        :key="index"
    >
      <WidgetBuilder
          :metric-item.sync="layoutItem.positions[`row_${rowNumber}`].metric_items[index - 1]"
          :is-edit-mode="isEditMode"
      />
    </div>
  </div>

</template>

<script>

import WidgetBuilder from '../widgets/WidgetBuilder.vue';

export default {
  name: 'OneRowLayout',
  components: { WidgetBuilder },
  props: {
    layoutItem: {
      type: Object,
      default: () => ({}),
    },

    rowNumber: {
      type: Number,
      default: 1,
    },

    isEditMode: {
      type: Boolean,
    },
  },

  computed: {
    layoutClass() {
      const styleClass = [];

      if (
        this.layoutItem.positions[`row_${this.rowNumber}`].options
          && this.layoutItem.positions[`row_${this.rowNumber}`].options.class) {
        styleClass.push(this.layoutItem.positions[`row_${this.rowNumber}`].options.class);
      }

      const maxColumnsNumber = this.layoutItem.positions[`row_${this.rowNumber}`].options.max_capacity
        ? this.layoutItem.positions[`row_${this.rowNumber}`].options.max_capacity
        : 1;

      styleClass.push(`ncol-${maxColumnsNumber}`);

      return styleClass.join(' ');
    },
  },
};
</script>

<style scoped>
.nrow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  padding-bottom: 25px;
}
.ncol-4 .ncol {
  width: 24%;
}

.ncol-3 .ncol {
  width: 32%;
}

.ncol-2 .ncol {
  width: 48%;
}

.ncol-1 .ncol {
  width: 100%;
}

.bordered {
  border: 1px solid #ccc;
  border-radius: 8px;
}
</style>

<style>
.header > .ncol > div {
  height: 100%;
}

.blue-background-row > .ncol > div > .sum,
.blue-background-on-the-top-row > .ncol > div > .sum
{
  padding: 40px 20px;
  background: #5ab4e6;
  color: #fff;
  border-radius: 8px;
}

.blue-background-row > .ncol > div > .sum h2,
.blue-background-on-the-top-row > .ncol > div > .sum h2
{
  color: #fff;
}

.pies-row > .ncol > div,
.pies-row .card {
  height: 100%;
  margin-bottom: 0;
}

.blue-background-on-the-top-row > .ncol > div {
  height: unset !important;
}
</style>
