<template>
  <Accordion
      title="By Browsers"
      subtitle="What browsers are being used?"
      :item-data="itemData"
  />
</template>

<script>

import Accordion from './Accordion.vue';

export default {
  name: 'AccordionBrowsers',
  components: {
    Accordion,
  },

  props: {
    itemData: {
      type: Array,
      default() {
        return ([]);
      },
    },
  },
};
</script>
