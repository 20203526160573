<template>
  <TablePaginated
      label="Total # of PAX per route"
      :main-list="itemData"
      :is-global-percent="isGlobalPercent"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import TablePaginated from '../../../TablePaginated.vue';

export default {
  name: 'PagedListFlightNumberUsers',
  components: {
    TablePaginated,
  },

  props: {
    itemData: {
      type: [Array],
      default() {
        return ([]);
      },
    },
  },

  computed: {
    ...mapGetters({
      isGlobalPercent: 'metrics/isGlobalPercent',
    }),
  },
};
</script>
