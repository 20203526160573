<template>
  <div>
    <div>
      <h4>
        Number of flights
      </h4>
      <div class="bar-chart-holder">
        <BarChart
            class="bar-chart"
            :item-data="itemData"
            :height="150"
        />
      </div>
    </div>
  </div>
</template>

<script>

import BarChart from './BarChart.vue';

export default {
  name: 'BarChartFlightNumbers',
  components: {
    BarChart,
  },

  props: {
    itemData: {
      type: Array,
      default() {
        return ([]);
      },
    },
  },
};
</script>
<style scoped>
/*bar chart*/
.bar-chart-holder {
  padding: 0;
}

.bar-chart {
  margin: 0 auto;
}
</style>
