<template>
  <TablePaginatedToggleButton
      label="Microsites"
      :main-list="itemData"
      :is-global-percent="isGlobalPercent"
      :override-headers="overrideHeaders"
      :tabs-list="tabsList"
      :visible-pages-numbers="5"
      is-has-tabs
      is-show-total-label
  />
</template>

<script>
import { mapGetters } from 'vuex';
import TablePaginatedToggleButton from '../../../TablePaginatedToggleButton.vue';

export default {
  name: 'PagedListMicrositesNoHighlights',
  components: {
    TablePaginatedToggleButton,
  },

  props: {
    itemData: {
      type: [Array],
      default() {
        return ([]);
      },
    },
  },

  data: () => ({
    overrideHeaders: [
      {
        text: '', value: 'label', tabs: ['every'],
      },
      {
        text: 'Total', value: 'value', type: 'bar', tabs: ['default'],
      },
      {
        text: 'Total', value: 'total', type: 'number', tabs: ['details'],
      },
      {
        text: 'View Microsites', value: 'show', type: 'number', tabs: ['details'],
      },
      {
        text: 'Destination Board', value: 'board', type: 'number', tabs: ['details'],
      },
    ],
    tabsList: [
      { text: 'Overview', name: 'default' },
      { text: 'Details', name: 'details' },
    ],
  }),

  computed: {
    ...mapGetters({
      isGlobalPercent: 'metrics/isGlobalPercent',
    }),
  },
};
</script>
