<template>
  <div>
    <v-row
        no-gutters
    >
      <v-card
          ref="topdf"
          width="100%"
          outlined
          class="pa-6"
      >
        <v-row>
          <v-col
              :cols="2"
          />
          <v-col>
            <v-card
                height="300"
            >
              <AppIframe
                  v-if="isLocalStorageEnabled"
                  :url="airportAppUrl"
              />
              <v-card-text
                  v-else
              >
                <v-alert
                    type="warning"
                    outlined
                >
                  Please, disable block third-party cookies to activate the application.
                </v-alert>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-row>
              <v-col
                  style="color: #08aef5"
                  class="d-flex justify-center text-h4"
              >
                WhereWeFly
              </v-col>
            </v-row>
            <v-row>
              <v-col
                  v-for="num in layoutItem.positions.row_1.options.max_capacity"
                  :key="num"
              >
                <WidgetBuilder
                    :metric-item.sync="layoutItem.positions.row_1.metric_items[num - 1]"
                    :is-edit-mode="isEditMode"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col
              :cols="2"
          />
        </v-row>
        <v-row>
          <v-col
              :cols="4"
              v-for="num in layoutItem.positions.row_2.options.max_capacity"
              :key="num"
          >
            <WidgetBuilder
                :metric-item.sync="layoutItem.positions.row_2.metric_items[num - 1]"
                :is-edit-mode="isEditMode"
            />
          </v-col>
        </v-row>
        <v-btn
            v-if="false"
            data-html2canvas-ignore="true"
            absolute
            right
            bottom
            @click="saveAsPdf"
        >
          <v-icon
              x-large
              color="#0000001f"
          >
            mdi-file-pdf-box
          </v-icon>
        </v-btn>
      </v-card>
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col
              class="d-flex justify-center text-h4 grey--text"
          >Content Statics</v-col>
        </v-row>
        <v-row>
          <v-col
              v-for="num in layoutItem.positions.row_3.options.max_capacity"
              :key="num"
          >
            <WidgetBuilder
                :metric-item.sync="layoutItem.positions.row_3.metric_items[num - 1]"
                :is-edit-mode="isEditMode"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WidgetBuilder from '../widgets/WidgetBuilder.vue';
import AppIframe from '../../AppIframe.vue';

export default {
  name: 'OverviewAirportLayout',
  components: {
    AppIframe,
    WidgetBuilder,
  },
  props: {
    layoutItem: {
      type: Object,
      default: () => ({}),
    },

    isEditMode: {
      type: Boolean,
    },
  },

  methods: {
    isLocalStorageEnabled() {
      try {
        const key = '__storage__test';
        window.localStorage.setItem(key, null);
        window.localStorage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    },
  },

  computed: {
    ...mapGetters({
      airportAppUrl: 'metrics/airportAppUrl',
    }),
  },
};
</script>
