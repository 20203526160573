<template>
  <TablePaginated
      label="Popular Destinations"
      sublabel="What locations do your passengers view the most?"
      :main-list="itemData"
      :is-global-percent="isGlobalPercent"
      @setActiveItem="setActiveDestinationItem"
      is-mark-active-item
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import TablePaginated from '../../../TablePaginated.vue';

export default {
  name: 'PagedListPopularDestinations',
  components: {
    TablePaginated,
  },

  props: {
    itemData: {
      type: [Array],
      default() {
        return ([]);
      },
    },
  },

  methods: {
    ...mapActions({
      setActiveDestinationNumber: 'metrics/setActiveDestination',
    }),

    setActiveDestinationItem(item) {
      this.setActiveDestinationNumber(this.itemData
      // eslint-disable-next-line max-len
        .findIndex((el) => (item.id != null && el.id === item.id) || (item.label != null && el.label === item.label)));
    },
  },

  computed: {
    ...mapGetters({
      isGlobalPercent: 'metrics/isGlobalPercent',
    }),
  },
};
</script>
