<template>
  <div>
    <ConfirmDialog
        :is-open.sync="dialogs.selectMetric.isOpened"
        confirm-button-text="Set"
        :is-disable-confirm-button="!getDialogData('selectMetric', 'metricId')"
        @confirm="setMetric"
    >
      <template v-slot:title>
        Select metric
      </template>
      <template v-slot:body>
        <v-row
            justify="center"
            align="center"
        >
          <v-col>
            <v-select
                label="metrics"
                v-model="dialogs.selectMetric.data.metricId"
                :items="metricListComputed"
            >
              <template v-slot:item="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                        v-bind="attrs"
                        v-on="on"
                    >{{ item.text }}</span>
                  </template>
                  {{ item.description }}
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row
            justify="center"
            align="center"
        >
          <v-col :cols="12">
            {{ selectedMetric.description }}
          </v-col>
        </v-row>
      </template>
    </ConfirmDialog>
    <div
        class="empty-metric"
        align="center"
    >
      <v-btn
          x-large
          @click="openDialog('selectMetric')"
      >
        <v-icon
            x-large
            color="green"
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import manageDialogsMixin from '../../../mixins/manageDialogsMixin';
import ConfirmDialog from '../../dialogs/ConfirmDialog.vue';

export default {
  name: 'SelectMetric',
  components: {
    ConfirmDialog,
  },

  mixins: [
    manageDialogsMixin,
  ],

  props: {
    metricItem: {
      type: Object,
    },
  },

  data: () => ({
    metricList: [],

    dialogs: {
      selectMetric: {
        isOpened: false,
        data: {
          metricId: null,
        },
        defaults: {
          metricId: null,
        },
      },
    },
  }),

  async created() {
    this.metricList = this.listItem(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_METRICS.type);
    await this.updateList(this.$constants.DATA_LISTS.DASHBOARD_ELEMENTS_METRICS);
  },

  methods: {
    ...mapActions({
      updateList: 'datalists/updateList',
    }),

    setMetric() {
      this.$emit(
        'update:metricItem',
        {
          slug: this.selectedMetric.slug,
          metrics: this.selectedMetric.metric_slugs,
        },
      );
    },
  },

  computed: {
    ...mapGetters({
      listItem: 'datalists/listItem',
    }),

    selectedMetric() {
      // eslint-disable-next-line max-len
      const metric = this.metricList.list.find((el) => el.id === this.dialogs.selectMetric.data.metricId);

      return !metric ? { description: '' } : metric;
    },

    metricListComputed() {
      if (!this.metricList.list) {
        return [];
      }

      const categoriesList = {};

      const flatList = [];

      this.metricList.list.forEach((el) => {
        if (categoriesList[el.category] === undefined) {
          categoriesList[el.category] = [];
        }
        categoriesList[el.category].push(el);
      });

      const categories = Object.keys(categoriesList);

      categories.forEach((cat) => {
        flatList.push({ header: cat, description: cat });

        categoriesList[cat].sort((a, b) => (a.slug <= b.slug ? -1 : 1));

        categoriesList[cat].forEach((el) => {
          flatList.push({ text: el.slug, value: el.id, description: el.description });
        });
      });

      return flatList;
    },
  },
};
</script>

<style scoped>

</style>
