<template>
  <div>
    <OneRowLayout
      :row-number="1"
      :layout-item.sync="layoutEdit"
      :is-edit-mode="isEditMode"
    />
    <OneRowLayout
      :row-number="2"
      :layout-item.sync="layoutEdit"
      :is-edit-mode="isEditMode"
    />
  </div>
</template>

<script>

import OneRowLayout from './OneRowLayout.vue';

export default {
  name: 'TwoRowsLayout',

  components: {
    OneRowLayout,
  },

  props: {
    layoutItem: {
      type: Object,
      default: () => ({}),
    },

    isEditMode: {
      type: Boolean,
    },
  },

  computed: {
    layoutEdit: {
      get() {
        return this.layoutItem;
      },

      set(val) {
        this.$emit('update:layoutItem', ({ ...this.layoutItem, layout: val }));
      },
    },
  },
};
</script>
