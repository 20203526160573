<template>
  <SingleNumberFrame
      title="Number of tails"
      :value="itemData"
  >
  </SingleNumberFrame>
</template>

<script>

import SingleNumberFrame from './SingleNumberFrame.vue';

export default {
  name: 'SingleNumberTailNumberNumbers',
  components: {
    SingleNumberFrame,
  },

  props: {
    itemData: {
      type: [Number, String],
      default: null,
    },
  },
};
</script>
