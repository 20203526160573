<template>
  <TablePaginatedToggleButton
      label="Total # of PAX per route"
      :main-list="itemData"
      :is-global-percent="isGlobalPercent"
      :override-headers="overrideHeaders"
  >
  </TablePaginatedToggleButton>
</template>

<script>
import { mapGetters } from 'vuex';
import TablePaginatedToggleButton from '../../../TablePaginatedToggleButton.vue';

export default {
  name: 'PagedListFlightsAndUsers',
  components: {
    TablePaginatedToggleButton,
  },

  props: {
    itemData: {
      type: [Array],
      default() {
        return ([]);
      },
    },
  },

  data: () => ({
    overrideHeaders: [
      {
        text: '', value: 'label', tabs: ['every'],
      },
      {
        text: '# of flights', value: 'flights', type: 'number', tabs: ['default'],
      },
      {
        text: '# of PAX', value: 'passengers', type: 'number', tabs: ['default'],
      },
    ],
  }),

  computed: {
    ...mapGetters({
      isGlobalPercent: 'metrics/isGlobalPercent',
    }),
  },
};
</script>
