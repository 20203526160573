<template>
  <TabsComponent
    :tabs="clearTabs"
  >
    <template v-slot:popular_places>
      <TablePaginatedToggleButton
          sublabel="What attractions do your passengers view the most?"
          :main-list="tabItemData('popular_places')"
          :is-global-percent="isGlobalPercent"
          :override-headers="tabMataData('popular_places').overrideHeaders"
          :tabs-list="tabMataData('popular_places').tabsList"
          :visible-pages-numbers="5"
          is-has-tabs
          is-show-total-label
      />
    </template>

    <template v-slot:advertisement>
      <TablePaginatedToggleButton
          :main-list="tabItemData('advertisement')"
          :is-global-percent="isGlobalPercent"
          :visible-pages-numbers="5"
          is-has-tabs
          is-show-total-label
      />
    </template>

    <template v-slot:microsites>
      <TablePaginatedToggleButton
          :main-list="tabItemData('microsites')"
          :is-global-percent="isGlobalPercent"
          :override-headers="tabMataData('microsites').overrideHeaders"
          :tabs-list="tabMataData('microsites').tabsList"
          :visible-pages-numbers="5"
          is-has-tabs
          is-show-total-label
      />
    </template>

    <template v-slot:playlists>
      <TablePaginatedToggleButton
          :main-list="tabItemData('playlists')"
          :is-global-percent="isGlobalPercent"
          :override-headers="tabMataData('playlists').overrideHeaders"
          :tabs-list="tabMataData('playlists').tabsList"
          :visible-pages-numbers="5"
          is-has-tabs
          is-show-total-label
      />
    </template>
  </TabsComponent>

</template>

<script>
import { mapGetters } from 'vuex';
import TablePaginatedToggleButton from '../../../TablePaginatedToggleButton.vue';
import TabsComponent from '../../../TabsComponent.vue';

export default {
  name: 'TabsDestinationRelations',
  components: {
    TabsComponent,
    TablePaginatedToggleButton,
  },

  props: {
    itemData: {
      type: [Array, Object],
      default() {
        return ([]);
      },
    },
  },

  data: () => ({
    tabs: [
      {
        name: 'Popular places',
        slug: 'popular_places',
        data: {
          overrideHeaders: [
            {
              text: '', value: 'label', tabs: ['every'],
            },
            {
              text: 'Total', value: 'value', type: 'bar', tabs: ['default'],
            },
            {
              text: 'Total', value: 'total', type: 'number', tabs: ['details'],
            },
            {
              text: 'View Video', value: 'click', type: 'number', tabs: ['details'],
            },
            {
              text: 'Destination Board', value: 'board', type: 'number', tabs: ['details'],
            },
            {
              text: 'Map Spotlight', value: 'pins', type: 'number', tabs: ['details'],
            },
            {
              text: 'AI Feed', value: 'highlights', type: 'number', tabs: ['details'],
            },
            {
              text: 'View Info', value: 'view_info', type: 'number', tabs: ['details'],
            },
          ],
          tabsList: [
            { text: 'Overview', name: 'default' },
            { text: 'Details', name: 'details' },
          ],
        },
      },
      {
        name: 'Advertisement',
        slug: 'advertisement',
        data: {
        },
      },
      {
        name: 'Microsites',
        slug: 'microsites',
        data: {
          overrideHeaders: [
            {
              text: '', value: 'label', tabs: ['every'],
            },
            {
              text: 'Total', value: 'value', type: 'bar', tabs: ['default'],
            },
            {
              text: 'Total', value: 'total', type: 'number', tabs: ['details'],
            },
            {
              text: 'View Microsites', value: 'show', type: 'number', tabs: ['details'],
            },
            {
              text: 'Destination Board', value: 'board', type: 'number', tabs: ['details'],
            },
            {
              text: 'AI Feed', value: 'highlights', type: 'number', tabs: ['details'],
            },
          ],
          tabsList: [
            { text: 'Overview', name: 'default' },
            { text: 'Details', name: 'details' },
          ],
        },
      },
      {
        name: 'Playlists',
        slug: 'playlists',
        data: {
          overrideHeaders: [
            {
              text: '', value: 'label', tabs: ['every'],
            },
            {
              text: 'Total', value: 'value', type: 'bar', tabs: ['default'],
            },
            {
              text: 'Total', value: 'total', type: 'number', tabs: ['details'],
            },
            {
              text: 'Destination board click', value: 'board_click', type: 'number', tabs: ['details'],
            },
            {
              text: 'Destination board show', value: 'board_show', type: 'number', tabs: ['details'],
            },
            {
              text: 'AI Feed', value: 'highlights', type: 'number', tabs: ['details'],
            },
          ],
          tabsList: [
            { text: 'Overview', name: 'default' },
            { text: 'Details', name: 'details' },
          ],
        },
      },
    ],
  }),

  computed: {
    ...mapGetters({
      isGlobalPercent: 'metrics/isGlobalPercent',
    }),

    clearTabs() {
      return this.tabs.map((el) => ({ name: el.name, slug: el.slug }));
    },

    tabMataData() {
      return (slug) => this.tabs.find((el) => el.slug === slug).data;
    },

    tabItemData() {
      return (slug) => this.itemData[slug] || [];
    },
  },
};
</script>
