<template>
    <ChartBarHorizontal
        class="bar-chart"
        :chart-data="chartData"
        :options="horizontalBarChartOptions"
        :width="width"
        :height="height"
    />
</template>

<script>

import Vue from 'vue';
import ChartBarHorizontal from '../../../ChartBarHorizontal.vue';

const contractInteger = (num) => Vue.$numberManagement.contractInteger(num);
const numberWithComma = (num) => Vue.$numberManagement.numberWithComma(num);

export default {
  name: 'BarChart',
  components: {
    ChartBarHorizontal,
  },

  props: {
    itemData: {
      type: Array,
      default() {
        return ([]);
      },
    },

    label: {
      type: String,
      default: '',
    },

    width: {
      type: Number,
    },

    height: {
      type: Number,
    },

    barChartColor: {
      type: String,
      default: 'rgba(54, 162, 235, 1)',
    },
  },

  data() {
    return ({
      horizontalBarChartOptions: {
        legend: false,
        scales: {
          xAxes: [{
            ticks: {
              beginAtZero: true,
              callback: (value) => contractInteger(value),
            },
          }],
        },
        tooltips: {
          callbacks: {
            label(tooltipItem) {
              if (tooltipItem.value === null) {
                return '0';
              }

              return numberWithComma(tooltipItem.value);
            },
          },
        },
      },
    });
  },

  computed: {
    chartData() {
      return ({
        labels: this.itemData.map((el) => el.label),
        datasets: [{
          label: this.label,
          data: this.itemData.map((el) => el.value),
          borderColor: this.barChartColor,
          borderWidth: 1,
        }],
      });
    },
  },
};
</script>
