<template>
  <TablePaginated
      :label="activeRouteFilterName()"
      :main-list="itemData"
      :is-global-percent="isGlobalPercent"
      is-active
  />
</template>

<script>
import { mapGetters } from 'vuex';
import TablePaginated from '../../../TablePaginated.vue';

export default {
  name: 'PagedListFilterValues',
  components: {
    TablePaginated,
  },

  props: {
    itemData: {
      type: [Array],
      default() {
        return ([]);
      },
    },
  },

  computed: {
    ...mapGetters({
      isGlobalPercent: 'metrics/isGlobalPercent',
      activeRouteFilterName: 'metrics/activeRouteFilterName',
    }),
  },
};
</script>
