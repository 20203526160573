<template>
    <ChartBar
        class="bar-chart"
        :chart-data="chartData"
        :options="chartOptionsComputed"
        :width="width"
        :height="height"
    />
</template>

<script>

import Vue from 'vue';
import ChartBar from '../../../ChartBar.vue';

const timeValues = (value) => {
  let num1 = '';
  let num2 = '';

  if (value <= 100 * 60) {
    // (min:sec)
    const mins = Math.floor(value / 60);
    const sec = value % 60;

    num1 = `${mins}`;
    num2 = `${sec}`;
  }

  if (value > 100 * 60) {
    // (h:min)
    const hours = Math.floor(value / 3600);
    const mins = Math.floor((value - hours * 3600) / 60);

    num1 = `${hours}`;
    num2 = `${mins}`;
  }

  return { num1, num2 };
};

const limitedTimeValue = (value) => {
  // eslint-disable-next-line eqeqeq
  const { num1, num2 } = timeValues(value);

  return `${String(num1).padStart(2, '0')}:${String(num2).padStart(2, '0')}`;
};

const contractInteger = (num) => Vue.$numberManagement.contractInteger(num);
const numberWithComma = (num) => Vue.$numberManagement.numberWithComma(num);

export default {
  name: 'BarChart',
  components: {
    ChartBar,
  },

  props: {
    itemData: {
      type: Array,
      default() {
        return ([]);
      },
    },

    withTimeYAxe: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    width: {
      type: Number,
    },

    height: {
      type: Number,
    },

    barChartColor: {
      type: String,
      default: 'rgba(54, 162, 235, 1)',
    },
  },

  data() {
    return ({
      barChartTimeYOptions: {
        legend: false,
        scales: {
          yAxes: [{
            type: 'linear',
            ticks: {
              beginAtZero: true,
              callback: (value) => limitedTimeValue(value),
            },
          }],
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => limitedTimeValue(tooltipItem.value),
          },
        },
      },
    });
  },

  computed: {
    chartOptionsComputed() {
      return this.withTimeYAxe ? this.barChartTimeYOptions : this.barChartOptions;
    },

    chartData() {
      return ({
        labels: this.itemData.map((el) => el.label),
        datasets: [{
          label: this.label,
          data: this.itemData.map((el) => el.value),
          borderColor: this.barChartColor,
          borderWidth: 1,
        }],
      });
    },

    barChartOptions: () => ({
      legend: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            callback: (value) => contractInteger(value),
          },
        }],
      },
      tooltips: {
        callbacks: {
          label(tooltipItem) {
            if (tooltipItem.value === null) {
              return '0';
            }

            return numberWithComma(tooltipItem.value);
          },
        },
      },
    }),
  },
};
</script>
